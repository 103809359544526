import { ActivateElevResponse } from "@/types/activateElevResponse";
import { ElevListResponse } from "@/types/elevListeResponse";
import { GrantAccessResponse, RecallGrantAccessResponse } from "@/types/GrantAccess";
import { PasswordPolicyResponse } from "@/types/passwordPolicy";
import { ResetPasswordResponse } from "@/types/resetPassword";
import { SpaerElevResponse } from "@/types/spaerElevApiResponse";
import { StatusCode } from "@/types/statusCode";
import { UserInfo } from "@/types/userinfo";

const getStatusCode = (): StatusCode => {
  return {
    code: 0,
    message: "Der skete en fejl",
  };
};

export const EMPTY_USERINFO: UserInfo = {
  statusCode: getStatusCode(),
  csrfToken: "fejl",
  fullName: "",
  loginAsUserAdmin: false,
  loggedOnAs: "",
  userCvr: "",
  isAuthenticated: false,
  disableUserActions: true,
  institutions: [],
  aktoergruppe: ""
};

export const EMPTY_ELEVLIST_RESPONSE: ElevListResponse = {
  elever: [],
  statusCode: getStatusCode(),
  students: [],
};

export const EMPTY_ACTIVATE_KEYCLOAKUSERS_RESPONSE: ActivateElevResponse = {
  success: false,
  errorCode: getStatusCode(),
  data: "",
}

export const EMPTY_GIVE_ACCESS_RESPONSE: GrantAccessResponse = {
  statusCode: getStatusCode(),
  aktoerIds: [],
}

export const EMPTY_FORTRYD_GIVE_ACCESS_RESPONSE: RecallGrantAccessResponse = {
  statusCode: getStatusCode(),
}

export const EMPTY_PASSWORDPOLICY_RESPONSE: PasswordPolicyResponse = {
  aktoerId: "",
  statusCode: getStatusCode(),
}

export const EMPTY_RESETPASSWORD_RESPONSE: ResetPasswordResponse = {
  statusCode: getStatusCode(),
  data: [],
}

export const EMPTY_SPAER_ELEV_RESPONSE: SpaerElevResponse = {
  aktoerId: "",
  statusCode: getStatusCode(),
  action: "",
}
