import { createBrowserRouter } from "react-router-dom";
import { PATHS } from "@/constants/paths";
import ElevOversigt from "@/views/elevoversigt/ElevOversigt";
import MissingPrivileges from "@/components/error/MissingPrivileges";
import Main from "@/views/main/Main";
import { getUserInfo } from "@/services/UserinfoService";
import ElevTabelContainer from "@/components/elevtabel/ElevTabelContainer";
import IngenInstitutioner from "@/components/institutionsListe/IngenInstitutioner";
import Login from "@/views/login/Login";
import { getElevListeLoader } from "@/router/routerLoaders";
import WrongAktoer from "@/components/error/WrongAktoer";
import ProtectedRoute from "@/components/protectedRoute/ProtectedRoute";
import PrivateIdentityError from "@/components/error/PrivateIdentityError";
import MissingSecurityLevel from "@/components/error/MissingSecurityLevel";
import RootError from "@/views/errors/RootError";
import UnexpectedError from "@/components/error/UnexpectedError";
import NoInstitutions from "@/components/error/NoInstitutions";

export function getRouter() {
  return createBrowserRouter([
    {
      path: PATHS.FRONT,
      element: <Main />,
      loader: async () => await getUserInfo(),
      errorElement: <RootError />,
      children: [
        {
          path: PATHS.INDEX,
          element: <Login />,
        },
        {
          path: PATHS.ELEVOVERSIGT,
          element: (
            <ProtectedRoute>
              <ElevOversigt />
            </ProtectedRoute>
          ),
          children: [
            {
              path: PATHS.ELEVLISTE,
              loader: getElevListeLoader,
              element: <ElevTabelContainer />,
            },
            {
              path: PATHS.INGENINSTITUTIONS,
              element: <IngenInstitutioner />,
            },
          ],
        },
        {
          path: PATHS.MISSING_PRIVILEGES,
          element: <MissingPrivileges />,
        },
        {
          path: PATHS.WRONG_AKTOER_TYPER,
          element: <WrongAktoer />,
        },
        {
          path: PATHS.PRIVATE_IDENTITY_ERROR,
          element: <PrivateIdentityError />,
        },
        {
          path: PATHS.SECURITY_LEVEL_LOW,
          element: <MissingSecurityLevel />,
        },
        {
          path: PATHS.SERVER_ERROR,
          element: <UnexpectedError />,
        },
        {
          path: PATHS.NO_INSTITUTIONS,
          element: <NoInstitutions />,
        },
      ],
    },
  ]);
}
