import { useMemo, useState } from "react";

export default function useSearch<T>(searchData: T[], searchTerms: (keyof T)[]) {
  const [searchTerm, setSearchTerm] = useState("");

  const searchSearchTerms = (searchItem: T[keyof T], searchTerm: string, data: T) => {
    if (isString(searchItem)) {
      return searchString(searchItem, searchTerm);
    }
    if (isNumber(searchItem)) {
      return searchItem.toString().includes(searchTerm);
    }
    if (isFunction(searchItem)) {
      const functionResult = searchItem.call(data);
      if (isString(functionResult)) {
        return searchString(functionResult, searchTerm);
      }
      if (isNumber(functionResult)) {
        return searchItem.toString().includes(searchTerm);
      }
      return false;
    }
    return false;
  };

  const searchResult = useMemo(() => {
    if (searchTerm === "") {
      return searchData;
    }
    return searchData.filter((data) => {
      const dataToSearch = searchTerms.map((term) => data[term]);
      return !!dataToSearch.find((searchItem) => {
        return searchSearchTerms(searchItem, searchTerm, data);
      });
    });
  }, [searchData, searchTerm]);

  const performSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  return {
    performSearch,
    searchResult,
  };
}

const searchString = (data: string, searchTerm: string) => {
  const tokenSearchTerm = Array.from(new Set([...searchTerm.split(" ")]));
  const tokenData = Array.from(new Set([data, ...data.split(" ")]));

  return tokenSearchTerm.filter(search => !tokenData.find(d => d.toLowerCase().startsWith(search.toLowerCase()))).length === 0;
};

const isString = (data: unknown): data is string => {
  return typeof data === "string";
};

const isNumber = (data: unknown): data is number => {
  return typeof data === "number";
};

const isFunction = (data: unknown): data is Function => {
  return typeof data === "function";
};
