function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className={"col-12 col-lg-3"}>
              <ul className={"align-text-left nobullet-list"}>
                <li>
                  <strong>Ansvarlig myndighed</strong>
                </li>
                <li>
                  <p>Styrelsen for IT og Læring</p>

                  <p>Teglholmsgade 1<br/>
                  2450 København SV<br/>
                  <a href={"tel:+4533925000"}>Tlf. 3392 5000</a>
                  </p>
                </li>
              </ul>
            </div>
            <div className={"col-12 col-lg-3"}>
              <ul className={"align-text-left nobullet-list"}>
                <li>
                  <strong>Kontakt</strong>
                </li>
                <li>
                  <a href={"https://www.stil.dk/driftsstatus-og-support"} className={"function-link"}>Opret supportsag</a>
                </li>
              </ul>
            </div>
            <div className={"col-12 col-lg-3"}>
              <ul className={"align-text-left nobullet-list"}>
                <li>
                  <strong>Hjælpende links</strong>
                </li>
                <li>
                  <a href={"https://viden.stil.dk/display/STILVIDENOFFENTLIG/Unilogin"}
                     className={"function-link"}>
                    Vejledning
                  </a>
                </li>
              </ul>
            </div>
            <div className={"col-12 col-lg-3"}>
              <ul className={"align-text-left nobullet-list"}>
                <li>
                  <strong>Webtilgængelighed og cookies</strong>
                </li>
                <li>
                  <a href={"https://www.was.digst.dk/elevadgang-unilogin-dk-index"}
                    className={"function-link"}>Tilgængelighedserklæring</a>
                </li>
                <li>
                  <a href={"https://www.stil.dk/om-styrelsen/om-styrelsens-hjemmeside/persondatapolitik"}
                    className={"function-link"}>Privatlivspolitik
                    (cookies)
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
