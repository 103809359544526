import Skeleton from "react-loading-skeleton";
import ElevTabelHeader from "./ElevTabelHeader";
import "react-loading-skeleton/dist/skeleton.css";

export default function ElevTabelSkeleton() {
    return (
      <table className="table table--compact table--zebra">
        <ElevTabelHeader onCheckAll={(value) => null} checked={false}/>
        <tbody>
          {[1, 2, 3, 4, 5].map((value) => {
            return (
              <tr key={"skeletonrow" + value}>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
