import { Elev } from "@/model/elev";
import AdgangskodePolitik from "../adgangskode/AdgangskodePolitik";
import BaseDialog from "./BaseDialog";
import { PasswordPolicy, PasswordPolicyRequest } from "@/types/passwordPolicy";
import { forwardRef, useContext, useState } from "react";
import { setPasswordPolicy } from "@/services/passwordPolicyService";
import { UserContext } from "@/contexts/userContext";
import { createToastPolicyUpdated, createToastPolicyUpdatedError } from "@/util/toast";
import useToastStore from "@/store/toastStore";
import useInstitutionId from "@/hooks/institution-id";

type AdgangskodePolitikDialogType = {
  readonly elev?: Elev;
  readonly close: Function;
  readonly onUpdatePolicy: () => void;
};

const AdgangskodePolitikDialog = forwardRef<HTMLDialogElement, AdgangskodePolitikDialogType>(
    ({elev, close, onUpdatePolicy}, ref) => {
      const {csrfToken} = useContext(UserContext);
      const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
      const [selectedPolicy, setSelectedPolicy] = useState<PasswordPolicy>();
      const [loading, setLoading] = useState(false);
      const { addToast } = useToastStore();
      const onPolicyChange = (policyChange: { policy: PasswordPolicy; invalid: boolean }) => {
        setSubmitDisabled(policyChange.invalid);
        setSelectedPolicy(policyChange.policy);
      };
      const institutionId = useInstitutionId();

      const updatePolicy = async () => {
        if (elev && selectedPolicy) {
          setLoading(true);
          const request: PasswordPolicyRequest = {aktoerId: elev.getUuid(), passwordPolicy: selectedPolicy, institutionId}
          const response = await setPasswordPolicy(request, csrfToken);
          if (response.statusCode.code === 200) {
            addToast(createToastPolicyUpdated(elev, selectedPolicy));
          } else if (response.statusCode.code)  {
            addToast(createToastPolicyUpdatedError(elev, response.statusCode));
          }
          setLoading(false);
          onUpdatePolicy();
        }
      };

      return (
          <BaseDialog
              header="Ændring af adgangskodepolitik"
              primaryButtonText="Angiv regel"
              secondaryButtonText="Annuller"
              close={close}
              loading={loading}
              submitCallback={updatePolicy}
              primaryButtonDisabled={submitDisabled}
              ref={ref}
          >
            <AdgangskodePolitik elev={elev} onPolicyChange={onPolicyChange}/>
          </BaseDialog>
      );
    },
);

export default AdgangskodePolitikDialog;
