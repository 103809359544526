import { Elev } from "@/model/elev";
import React from "react";
import ElevTabelRow, { OnCheckedCallback } from "./ElevTabelRow";
import ElevTabelHeader from "./ElevTabelHeader";

type ElevTabelType = {
  elever: Elev[];
  onChecked: OnCheckedCallback;
  onActivatedElev: () => void;
  invokeShowSpaerElev: (elev: Elev) => void;
  invokeShowPolicy: (elev: Elev) => void;
  onCheckAll: (value: boolean) => void;
  checked: boolean;
  className?: string;
};

export default function ElevTabel({
  elever,
  onChecked,
  invokeShowPolicy,
  invokeShowSpaerElev,
  onActivatedElev,
  onCheckAll,
  checked,
  className = "",
}: ElevTabelType) {
  const defaultClassName = "table table--compact";
  const classList = [className, defaultClassName].filter((c) => c).join(" ");
  return (
    <table className={classList}>
      <ElevTabelHeader onCheckAll={onCheckAll} checked={checked} />
      <tbody>
        {elever.map((elev) => (
          <ElevTabelRow
            elev={elev}
            key={elev.getUuid()}
            onActivatedElev={onActivatedElev}
            showSpaerElev={invokeShowSpaerElev}
            isChecked={checked}
            onChecked={onChecked}
            showPolicy={invokeShowPolicy}
          />
        ))}
      </tbody>
    </table>
  );
}
