import { UserInfo } from "@/types/userinfo";
import { PATHS } from "../../constants/paths";

type LoginTeacherButtonType = {
  readonly userinfo: UserInfo;
};

export default function LoginTeacherButton({ userinfo }: LoginTeacherButtonType) {
  return (
    <form action={PATHS.LOGIN_TEACHER_PATH} className="ml-3">
      <input name="_csrf" type="hidden" value={userinfo?.csrfToken} />
      <button className={"button button-primary button-row"}>Log på som lærer</button>
    </form>
  );
}
