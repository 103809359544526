import baseFetch from "@/services/baseService";
import { PasswordPolicyRequest, PasswordPolicyResponse } from "@/types/passwordPolicy";
import { API_PATHS } from "@/constants/paths";
import { EMPTY_PASSWORDPOLICY_RESPONSE } from "@/constants/errors";

export const setPasswordPolicy = async (request: PasswordPolicyRequest, token: string) => {
  return await baseFetch<PasswordPolicyResponse>(API_PATHS.PASSWORD_POLICY, EMPTY_PASSWORDPOLICY_RESPONSE, {
    method: "POST",
    body: JSON.stringify(request),
    token
  })
}
