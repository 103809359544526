import { Elev } from "@/model/elev";
import { PasswordPolicy, passwordPolicies } from "@/types/passwordPolicy";
import { useEffect, useState } from "react";

type AdgangskodePolitikType = {
  readonly elev?: Elev;
  readonly onPolicyChange: (policyChange: { policy: PasswordPolicy; invalid: boolean }) => void;
};

export default function AdgangskodePolitik({ elev, onPolicyChange }: AdgangskodePolitikType) {
  const [valgtPassword, setValgtPassword] = useState<PasswordPolicy>(elev?.getPolicyOverride() ?? "STANDARD");
  const [konsekvens, setKonsekvens] = useState<boolean>(false);

  useEffect(() => {
    setValgtPassword(elev?.getPolicyOverride() ?? "STANDARD");
    setKonsekvens(false);
  }, [elev?.getUuid()]);

  useEffect(() => {
    onPolicyChange({ policy: valgtPassword, invalid: !konsekvens });
  }, [konsekvens, valgtPassword]);
  return (
    <form>
      <p className="displayheading-2">Du er ved at ændre adgangspolitik for {elev?.getFullName()}</p>
      <fieldset aria-labelledby="radio-example-legend" className="mb-5">
        <legend>Ophæv nuværende regel ved adgangskode, vælg en anden type herunder.</legend>
        <div className="row">
          <div className="mt-5 col-lg-4 col-md-6 col-sm-8">
            {passwordPolicies.map((passwordPolicy) => {
              const id = "password" + passwordPolicy.value;
              return passwordPolicy !== null ? (
                <div className="form-group-radio" key={passwordPolicy.value}>
                  <input
                    type="radio"
                    id={id}
                    name="password-policy"
                    className="form-radio"
                    value={passwordPolicy.value!}
                    checked={valgtPassword === passwordPolicy.value}
                    onChange={() => setValgtPassword(passwordPolicy.value)}
                  />
                  <label className="form-label" htmlFor={id}>
                    {passwordPolicy.text}
                  </label>
                </div>
              ) : (
                <></>
              );
            })}
          </div>
        </div>
        <div className="form-group">
          <div className="form-group-checkbox">
            <input
              type="checkbox"
              id="konsekevens"
              name="konsekvens"
              className="form-checkbox"
              checked={konsekvens}
              onChange={({ currentTarget }) => setKonsekvens(currentTarget.checked)}
            />
            <label className="form-label" htmlFor="konsekevens">
              Er du indforstået med disse konsekvenser? (
              <a href="https://viden.stil.dk/display/OFFSKOLELOGIN/Adgangskodepolitik">læs mere</a>)
            </label>
          </div>
        </div>
      </fieldset>
    </form>
  );
}
