import { ReactNode, forwardRef } from "react";

type BaseDialogType = {
  header: string;
  submitCallback: Function;
  primaryButtonText: string;
  secondaryButtonText?: string;
  children?: ReactNode;
  loading?: boolean;
  close: Function;
  primaryButtonDisabled?: boolean;
};

const BaseDialog = forwardRef<HTMLDialogElement, BaseDialogType>(
  (
    {
      header,
      submitCallback,
      children,
      primaryButtonText,
      secondaryButtonText,
      primaryButtonDisabled = false,
      close,
      loading = false,
    }: BaseDialogType,
    ref,
  ) => {

    return (
      <dialog ref={ref}>
        <div className="modal-header">
          <h2>{header}</h2>
        </div>
        <div className="modal-content">{children}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="button button-primary"
            onClick={() => submitCallback()}
            disabled={loading || primaryButtonDisabled}
          >
            {primaryButtonText}
          </button>
          {secondaryButtonText && (
            <button type="button" className="button button-secondary" onClick={() => close()} disabled={loading}>
              {secondaryButtonText}
            </button>
          )}
        </div>
      </dialog>
    );
  },
);

export default BaseDialog;
