import { forwardRef, useContext, useState } from "react";
import BaseDialog from "./BaseDialog";
import { Elev } from "@/model/elev";
import { UserContext } from "@/contexts/userContext";
import { fortrydGiveEleverAccess } from "@/services/elevListeService";
import { createToastFortrydAccess, createToastFortrydAccessError } from "@/util/toast";
import useToastStore from "@/store/toastStore";
import useInstitutionId from "@/hooks/institution-id";

type FortrydEleverAccressDialogType = {
  elever: Elev[];
  onFortrydGiveAccess: () => void;
  close: Function;
};

const FortrydEleverAccessDialog = forwardRef<HTMLDialogElement, FortrydEleverAccressDialogType>(
  ({ elever, onFortrydGiveAccess, close }: FortrydEleverAccressDialogType, ref) => {
    const { csrfToken } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToastStore();
    const institutionId = useInstitutionId();
    const fortrydGiveAccess = async () => {
      setLoading(true);
      const eleverUuids = elever.map((elev) => elev.getUuid());
      const result = await fortrydGiveEleverAccess({aktoerIds: eleverUuids, institutionId}, csrfToken);
      setLoading(false);
      if (result.statusCode.code === 200) {
        addToast(createToastFortrydAccess(elever));
      } else if (result.statusCode.code)  {
        addToast(createToastFortrydAccessError(elever, result.statusCode));
      }
      onFortrydGiveAccess();
    };
    return (
      <BaseDialog
        ref={ref}
        header="Fortryd 'Giv adgang'"
        primaryButtonText="Fortryd 'Giv adgang'"
        secondaryButtonText="Annuller"
        submitCallback={fortrydGiveAccess}
        loading={loading}
        close={() => close()}
      >
        {elever.length === 1 ? (
          <p>
            Vil du give fortryde ekstra rettigheder for{" "}
            <strong>
              <u>{elever[0].getFullName()}</u>
            </strong>
            ?
          </p>
        ) : (
          <p>
            Vil du give fortryde ekstra rettigheder for
            <strong>
              <u>{elever.length}</u>
            </strong>
            ?
          </p>
        )}
      </BaseDialog>
    );
  },
);

export default FortrydEleverAccessDialog;
