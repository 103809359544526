import { UserInfo } from "@/types/userinfo";
import LogoutButton from "@/components/button/LogoutButton";
import LoginAdminButton from "../button/LoginAdminButton";
import LoginTeacherButton from "../button/LoginTeacherButton";
import ShortUserInfo from "@/components/user/ShortUserInfo";

type UserViewProps = { readonly userinfo: UserInfo };

export default function UserView({ userinfo }: UserViewProps) {
  return (
    <>
      {userinfo.isAuthenticated ? (
        <>
          <ShortUserInfo userinfo={userinfo} />
          <LogoutButton csrfToken={userinfo.csrfToken} />
        </>
      ) : (
        <>
          <LoginAdminButton userinfo={userinfo} />
          <LoginTeacherButton userinfo={userinfo} />
        </>
      )}
    </>
  );
}
