import { components } from "./schema";

export type PasswordPolicy = components["schemas"]["Student"]["policyOverride"];

export const passwordPolicies: {
  text: string;
  value: PasswordPolicy;
}[] = [
  {
    text: "Indskoling",
    value: "INDSKOLING",
  },
  {
    text: "Mellemtrin",
    value: "MELLEMSKOLE",
  },
  {
    text: "Udskoling",
    value: "UDSKOLING",
  },
  {
    text: "Standard (automatisk)",
    value: "STANDARD",
  },
];

export type PasswordPolicyRequest = components["schemas"]["PasswordPolicyRequest"];

export type PasswordPolicyResponse = components["schemas"]["PasswordPolicyResponse"];
