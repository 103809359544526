import { useMemo, useState } from "react";

export default function useElevFilter<T>(filterData: T[]) {
  const [activeFilters, setActiveFilters] = useState<(keyof T)[]>([]);

  const performMatch = (data: T) =>
    activeFilters.find(filter => {
      const dataProperty = data[filter];
      return typeof dataProperty === "function" && dataProperty.call(data);
    });

  const filteredData = useMemo(() => {
    if (activeFilters.length === 0) {
      return filterData;
    }
    return filterData.filter(data => performMatch(data));
  }, [activeFilters.length, filterData]);

  const addFilter = (filter: keyof T) => {
    setActiveFilters([...activeFilters, filter]);
  };

  const removeFilter = (filter: keyof T) => {
    setActiveFilters([...activeFilters.filter(f => f !== filter)]);
  };

  return { addFilter, removeFilter, filteredData, activeFilters };
}
