import { API_PATHS } from "@/constants/paths";
import baseFetch from "./baseService";
import { Elev } from "@/model/elev";
import { SpaerElevApiResponse, SpaerElevResponse } from "@/types/spaerElevApiResponse";
import { EMPTY_SPAER_ELEV_RESPONSE } from "@/constants/errors";
import { UserAbilityRequest } from "@/types/userAbility";

export const toggleSpaerringForElev =
  async (elev: Elev, request: UserAbilityRequest, token: string): Promise<SpaerElevResponse> => {
  const isEnabled = elev.getEnabled();
  const path = isEnabled ? API_PATHS.SPAER_ELEV : API_PATHS.OPHAEV_SPAERRING_ELEV;
  const action = isEnabled ? "disable" : "enable";

  const result = await baseFetch<SpaerElevApiResponse>(
    path, EMPTY_SPAER_ELEV_RESPONSE, {method: "PUT", token, body: JSON.stringify(request)});

  return {...result, action}
};
