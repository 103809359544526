import { UserInfo } from "@/types/userinfo";
import UserView from "./UserView";
import { Link } from "react-router-dom";
import { PATHS } from "@/constants/paths";

export default function PortalInfo({ user }: { user: UserInfo }) {
  return (
    <div className="portal-info">
      <div className="portal-info-inner container">
        <Link to={PATHS.ELEVOVERSIGT} title="Gå til Portalnavns forside" aria-label="Portalnavn" className="logo">
          <span>Portalnavn</span>
        </Link>
        <button className="function-link button-menu-open js-menu-open ml-auto d-print-none" aria-haspopup="dialog">
          <svg className="icon-svg" focusable="false" aria-hidden="true">
            <use xlinkHref="#menu"></use>
          </svg>
          Menu
        </button>

        <div className="portal-user">{!user.disableUserActions && <UserView userinfo={user} />}</div>
      </div>
    </div>
  );
}
