import "../../assets/scss/index.scss";
import { init } from "dkfds";

import { RouterProvider } from "react-router-dom";
import { getRouter } from "@/router/router";
import IconCollection from "@/components/icons/IconCollection";
export default function App() {
  init();
  return (
    <>
      <IconCollection />
      <RouterProvider router={getRouter()} />
    </>
  );
}
