import { ChangeEvent, useEffect, useRef } from "react";
import { Dropdown } from "dkfds";

type FilterButtonType<T> = {
  filterOptions: { text: string, filter: keyof T & string }[],
  onAddFilter: (filter: keyof T) => void,
  onRemoveFilter: (filter: keyof T) => void,
  numberOfActiveFilters: number,
}

export default function FilterButton<T>({
                                          filterOptions,
                                          onRemoveFilter,
                                          onAddFilter,
                                          numberOfActiveFilters
                                        }: FilterButtonType<T>) {
  const filterRef = useRef(null);
  useEffect(() => {
    new Dropdown(filterRef.current).init();
  }, [filterRef.current]);

  const toggleFilter = ({ currentTarget }: ChangeEvent<HTMLInputElement>) =>
    currentTarget.checked ? onAddFilter(currentTarget.value as keyof T) : onRemoveFilter(currentTarget.value as keyof T);

  return <div className="overflow-menu overflow-menu--open-center filter">
    <button ref={filterRef} className="button-overflow-menu js-dropdown button" data-js-target="filtermenu"
            aria-expanded="false" aria-controls="filtermenu" title={"Filtrer elever"}>
      <svg className="icon-svg" focusable="false" aria-hidden="true">
        <use xlinkHref="#filter"></use>
      </svg>
      {numberOfActiveFilters > 0 && <>({numberOfActiveFilters})</>}
    </button>
    <div className="overflow-menu-inner pl-2" id="filtermenu" aria-hidden="true">
      {filterOptions.map(({ text, filter }) => <div key={filter} className="form-group-checkbox">
        <input type="checkbox" id={filter} className="form-checkbox" value={filter} onChange={toggleFilter} />
        <label className="form-label" htmlFor={filter}>{text}</label>

      </div>)}

    </div>
  </div>;
}
