import StatusBesked from "../statusbesked/StatusBesked";
import SeMereLink from "@/components/error/SeMereLink";

export default function NoInstitutions() {
  // Vi ved en lærer aldrig vil se denne besked. Derfor går vi ud fra, at det er en brugeradministrator.
  return (
    <StatusBesked type="warning" header="Ingen institutioner">
      <p>
        Der fandtes ingen institutioner, som du er tilknyttet som medarbejder samt har MitID Erhverv-rettigheder til.
      </p>
      <p>
        Du skal være tilknyttet den eller de institutioner, som du har MitID Erhverv-rettigheder til.
        Kontakt din institution eller MitID-administrator.
      </p>
      <SeMereLink/>
    </StatusBesked>
  );
}
