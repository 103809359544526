import { DEFAULT_PAGINATION_AMOUNT } from "@/constants/pagination";
import { useMemo, useState } from "react";

export default function usePagination<T>(data: T[], page: number, perPage: number) {
  const minimumPage = 1;
  const getNewPaginationKey = () => {
    return "pagination" + Date.now();
  };

  const [currentPage, setCurrentPage] = useState(page > minimumPage ? page : minimumPage);
  const [amountToShow, setAmountToShow] = useState(perPage);
  const [paginationKey, setPaginationKey] = useState(getNewPaginationKey());
  const resetPagination = () => {
    setCurrentPage(1);
    setAmountToShow(DEFAULT_PAGINATION_AMOUNT);
    setPaginationKey(getNewPaginationKey());
  };

  const nextPage = () => {
    const newPage = currentPage + 1;
    if (newPage <= getNumberOfPages) {
      setCurrentPage(newPage);
    } else {
      setCurrentPage(getNumberOfPages);
    }
  };
  const previousPage = () => {
    const newPage = currentPage - 1;
    newPage >= minimumPage ? setCurrentPage(newPage) : setCurrentPage(minimumPage);
  };

  const goToPage = (page: number) => {
    if (page <= getNumberOfPages) {
      setCurrentPage(page);
    } else {
      setCurrentPage(getNumberOfPages);
    }
  };

  const totalEntries = useMemo(() => data.length, [data]);

  const getNumberOfPages = useMemo(() => {
    return Math.ceil(totalEntries / amountToShow);
  }, [amountToShow, data]);

  const currentData = useMemo(() => {
    const startingPoint = currentPage * amountToShow - amountToShow;
    return data.slice(startingPoint, startingPoint + amountToShow);
  }, [amountToShow, currentPage, data]);

  return {
    nextPage,
    previousPage,
    goToPage,
    setAmountToShow,
    resetPagination,
    setCurrentPage,
    data,
    currentPage,
    currentData,
    getNumberOfPages,
    totalEntries,
    paginationKey,
  };
}
