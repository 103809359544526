import { forwardRef, useContext, useState } from "react";
import BaseDialog from "./BaseDialog";
import { Elev } from "@/model/elev";
import { activate } from "@/services/elevListeService";
import { UserContext } from "@/contexts/userContext";
import { ActivateElevResponse } from "@/types/activateElevResponse";
import { createToastEleverAktiveret, createToastEleverIkkeAktiveret } from "@/util/toast";
import useToastStore from "@/store/toastStore";

type ActivateEleverDialogType = {
  elever: Elev[];
  onActivate: () => void;
  close: Function;
};

const ActivateEleverDialog = forwardRef<HTMLDialogElement, ActivateEleverDialogType>(
  ({ elever, onActivate, close }: ActivateEleverDialogType, ref) => {
    const user = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [numberOfActivatedStudents, setNumberOfActivatedStudents] = useState(0);
    const { addToast } = useToastStore();

    const onMassActivate = (activatedElever: ActivateElevResponse[]) => {
      const successElever = activatedElever.filter((elev) => elev.success).map((data) => data.data);
      const warningElever = activatedElever.filter((elev) => !elev.success).map((data) => data.data);

      if (successElever.length) {
        addToast(createToastEleverAktiveret(successElever, activatedElever.length));
      }

      if (warningElever.length) {
        const eleverNotActivated = elever.filter((elev) => warningElever.includes(elev.getUniId()));
        addToast(createToastEleverIkkeAktiveret(eleverNotActivated));
      }
      onActivate();
    };

    const activateSomeElever = async (
      elever: Elev[],
      eleverResponse: ActivateElevResponse[],
    ): Promise<ActivateElevResponse[]> => {
      const AMOUNT_TO_ACTIVATE = 5;
      if (elever.length === 0) {
        return eleverResponse;
      }

      const eleverToActivate = elever.splice(0, AMOUNT_TO_ACTIVATE);
      const activatedElever = await activate(eleverToActivate, user.csrfToken);
      eleverResponse.push(...activatedElever);
      setNumberOfActivatedStudents(eleverResponse.length);

      return await activateSomeElever(elever, eleverResponse);
    };

    const activateElever = async () => {
      setNumberOfActivatedStudents(0);
      setLoading(true);
      const eleverToActive = Object.assign([], elever);
      const activatedElever = await activateSomeElever(eleverToActive, []);
      setLoading(false);
      onMassActivate(activatedElever);
    };
    return (
      <BaseDialog
        ref={ref}
        header="Aktiver elever"
        primaryButtonText="Aktiver"
        secondaryButtonText="Annuller"
        submitCallback={activateElever}
        loading={loading}
        close={() => close()}
      >
        <p>
          Du er i gang med aktivere følgende {elever.length} elev{elever.length > 2 && "er"}
        </p>
        <p>{elever.map((elev) => elev.getFullName()).join(", ")}</p>

        {loading && (
          <>
            <label htmlFor="aktivering-progress">Aktiverer elever</label>
            <br />
            <progress id="aktivering-progress" value={numberOfActivatedStudents} max={elever.length}>
              {Math.floor((numberOfActivatedStudents / elever.length) * 100)}%
            </progress>
          </>
        )}
      </BaseDialog>
    );
  },
);

export default ActivateEleverDialog;
