import { PATHS } from "@/constants/paths";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { components } from "@/types/schema";

type InstitutionsListeType = {
  readonly className?: string;
  readonly institutions: components["schemas"]["UserinfoInstitution"][];
};

export default function InstitutionsListe({ className, institutions }: InstitutionsListeType) {
  const navigate = useNavigate();
  const { institutionId } = useParams();
  const navigateToInstitution = (institutionId: string) => {
    navigate(PATHS.ELEVOVERSIGT + "/" + institutionId);
  };
  const [firstInstitution] = institutions;
  const shouldNavigate: boolean = !institutionId && institutionId !== firstInstitution?.instnr;

  return (
    <>
      {shouldNavigate ? (
        <Navigate to={PATHS.ELEVOVERSIGT + "/" + firstInstitution.instnr} />
      ) : (
        <div className={"form-group " + className}>
          <label className="form-label" htmlFor="institutioner">
            Vælg institution
          </label>
          <select
            className="form-select"
            id="institutioner"
            onChange={(event) => navigateToInstitution(event.currentTarget.value)}
            defaultValue={institutionId ?? ""}
          >
            {institutions.map((inst) => (
              <option value={inst.instnr} key={inst.instnr}>
                {inst.name} {inst.instnr}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}
