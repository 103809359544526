import { API_PATHS } from "@/constants/paths";
import baseFetch from "./baseService";
import { ResetPasswordResponse } from "@/types/resetPassword";
import { EMPTY_RESETPASSWORD_RESPONSE } from "@/constants/errors";

export const resetPasswords = async (aktoerIds: string[], institutionId: string, token: string): Promise<ResetPasswordResponse> => {
  return baseFetch(API_PATHS.RESET_PASSWORD, EMPTY_RESETPASSWORD_RESPONSE, {
    method: "PUT",
    body: JSON.stringify({ aktoerIds, institutionId }),
    token,
  });
};

export const recallResetPasswords = async (aktoerIds: string[], institutionId: string, token: string): Promise<ResetPasswordResponse> => {
  return baseFetch(API_PATHS.RECALL_RESET_PASSWORD, EMPTY_RESETPASSWORD_RESPONSE, {
    method: "PUT",
    body: JSON.stringify({ aktoerIds, institutionId }),
    token,
  });
};
