import baseFetch from "@/services/baseService";
import { API_PATHS } from "@/constants/paths";
import { ActivateElevResponse } from "@/types/activateElevResponse";
import {
  GrantAccessRequest,
  GrantAccessResponse,
  RecallGrantAccessRequest,
  RecallGrantAccessResponse
} from "@/types/GrantAccess";
import { ElevListResponse, mapToElever } from "@/types/elevListeResponse";
import { EMPTY_ACTIVATE_KEYCLOAKUSERS_RESPONSE, EMPTY_ELEVLIST_RESPONSE, EMPTY_FORTRYD_GIVE_ACCESS_RESPONSE, EMPTY_GIVE_ACCESS_RESPONSE } from "@/constants/errors";
import { Elev } from "@/model/elev";

export const getElevListe = async (institutionsId: string): Promise<ElevListResponse> => {
  const elevListResponse = await baseFetch<ElevListResponse>(
    API_PATHS.STUDENTS(institutionsId),
    EMPTY_ELEVLIST_RESPONSE,
  );
  elevListResponse.elever = mapToElever(elevListResponse);

  return elevListResponse;
};

export const activateElever = async (
  uniIds: string[],
  institutionId: string,
  token: string,
): Promise<ActivateElevResponse[]> => {
  const result = await baseFetch<ActivateElevResponse[]>(API_PATHS.AKTIVER_ELEVER, [EMPTY_ACTIVATE_KEYCLOAKUSERS_RESPONSE], {
    method: "POST",
    body: JSON.stringify({ uniIds, institutionId }),
    token,
  });
  return result;
};

export const giveEleverAccess = async (request: GrantAccessRequest, token: string) => {
  return baseFetch<GrantAccessResponse>(API_PATHS.GIVE_ACCESS, EMPTY_GIVE_ACCESS_RESPONSE, {
    method: "POST",
    body: JSON.stringify(request),
    token,
  });
};

export const fortrydGiveEleverAccess = async (request: RecallGrantAccessRequest, token: string) => {
  return baseFetch<RecallGrantAccessResponse>(API_PATHS.RECALL_ACCESS, EMPTY_FORTRYD_GIVE_ACCESS_RESPONSE,{
    method: "POST",
    body: JSON.stringify(request),
    token,
  });
};

export const activate = async (elever: Elev[], csrfToken: string) => {
  const elevUniIds = elever.map((elev) => elev.getUniId());
  return await activateElever(elevUniIds, elever[0].getInstitutionId(), csrfToken);
};
