import Layout from "../layout/Layout";
import { EMPTY_USERINFO } from "@/constants/errors";
import UnexpectedError from "@/components/error/UnexpectedError";

export default function RootError() {
  return (
    <Layout userinfo={EMPTY_USERINFO}>
      <UnexpectedError />
    </Layout>
  );
}
