import { ReactNode } from "react";

export type StatusBeskedType = {
  readonly type: "info" | "success" | "warning" | "error";
  readonly header: string;
  readonly children?: ReactNode;
};

export default function StatusBesked({ type, header, children }: StatusBeskedType) {

  const { style, icon, aria } = {
    info: { style: "alert alert-info", icon: "#info", aria: "Information" },
    warning: { style: "alert alert-warning", icon: "#report-problem", aria: "Advarsel" },
    success: { style: "alert alert-success", icon: "#check-circle", aria: "Success" },
    error: { style: "alert alert-error", icon: "#highlight-off", aria: "Fejl" }
  }[type];
  return (
    <div className={style}>
      <svg className="icon-svg alert-icon" focusable="false" aria-hidden="true" aria-label={aria}>
        <use xlinkHref={icon}></use>
      </svg>
      <div className="alert-body">
        <h2 className="alert-heading">{header}</h2>
        <div className="alert-text">{children}</div>
      </div>
    </div>
  );
}
