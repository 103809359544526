import StatusBesked from "@/components/statusbesked/StatusBesked";
import SeMereLink from "@/components/error/SeMereLink";

export default function MissingPrivileges() {
  return (
    <StatusBesked type={"error"} header={"Du har ikke den nødvendige rettighed i MitID Erhverv, til at tilgå Elevadgang"}>
      <p>
        Kontakt MitID Erhverv-administrator for at få rettigheden "Elevadgang: Ret til at administrere elever"
      </p>
      <SeMereLink/>
    </StatusBesked>
  );
}
