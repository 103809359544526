import { UserContext } from "@/contexts/userContext";
import { useContext } from "react";
import ButtonCountBadge from "../button/ButtonCountBadge";

type ElevTabelButtonRowType = {
  readonly onActivateElever: () => void;
  readonly onGiveEleverAccess: () => void;
  readonly onFortrydGivAdgang: () => void;
  readonly onResetPassword: () => void;
  readonly onRecallResetPassword: () => void;
  readonly eleverToActivate: number;
  readonly eleverToGiveAccess: number;
  readonly eleverToFortrydeGivadgang: number;
  readonly eleverToResetPassword: number;
  readonly eleverToFortrydResetpassword: number;
};

export default function ElevTabelButtonRow({
  onActivateElever,
  eleverToActivate,
  eleverToGiveAccess,
  eleverToFortrydeGivadgang,
  onGiveEleverAccess,
  onFortrydGivAdgang,
  eleverToResetPassword,
  eleverToFortrydResetpassword,
  onResetPassword,
  onRecallResetPassword,
}: ElevTabelButtonRowType) {
  const user = useContext(UserContext);

  return (
    <>
      {user.loginAsUserAdmin && (
        <ButtonCountBadge count={eleverToActivate} onClick={onActivateElever} buttonText="Aktiver" title="Aktiver elever"/>
      )}

      <ButtonCountBadge count={eleverToGiveAccess} onClick={onGiveEleverAccess} buttonText="Giv adgang" title="Giv elever adgang"/>

      <ButtonCountBadge count={eleverToResetPassword} onClick={onResetPassword} buttonText="Nulstil adgangskode" title="Nulstil adgangskode for elever"/>
      {!!eleverToFortrydeGivadgang && (
        <ButtonCountBadge count={eleverToFortrydeGivadgang} onClick={onFortrydGivAdgang} buttonText="Fortryd giv adgang" title="Fortryd giv adgang for elever"/>
      )}
      {!!eleverToFortrydResetpassword && (
        <ButtonCountBadge
          count={eleverToFortrydResetpassword}
          onClick={onRecallResetPassword}
          buttonText="Fortryd nulstil adgangskode"
          title="Fortryd nultil adgangskode for elever"
        />
      )}
    </>
  );
}
