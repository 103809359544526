export const PATHS = {
  FRONT: "/",
  INDEX: "/index",
  ELEVOVERSIGT: "/elevoversigt",
  ELEVLISTE: "/elevoversigt/:institutionId",
  INGENINSTITUTIONS: "/elevoversigt/no-institutions",
  LOGIN_TEACHER_PATH: "/oauth2/authorization/elevadgang",
  LOGIN_ADMIN_PATH: "/oauth2/authorization/elevadgang-admin",
  LOGOUT_PATH: "/logout",
  MISSING_PRIVILEGES: "/error/missing-privileges",
  WRONG_AKTOER_TYPER: "/error/wrong-aktoer",
  PRIVATE_IDENTITY_ERROR: "/error/private-identity-error",
  SECURITY_LEVEL_LOW: "/error/missing-security-level",
  SERVER_ERROR: "/error/server-error",
  NO_INSTITUTIONS: "/error/no-institutions",
};

export const API_PATHS = {
  USERINFO_PATH: "/api/userinfo",
  CSRF_TOKEN_PATH: "/api/csrftoken",
  STUDENTS: (institutionId: string) => `/api/institution/${institutionId}/students`,
  AKTIVER_ELEVER: "/api/students/activateKeycloakUsers",
  GIVE_ACCESS: "/api/students/grant-access",
  RECALL_ACCESS: "/api/students/recall-grant-access",
  PASSWORD_POLICY: "/api/students/update-password-policy",
  SPAER_ELEV: "/api/disable",
  OPHAEV_SPAERRING_ELEV: "/api/enable",
  RESET_PASSWORD: "/api/student/reset-password",
  RECALL_RESET_PASSWORD: "/api/student/recall-reset-password"
};

export type NavigationPaths = {
  path: string,
  text: string,
}

export const NAVIGATION_PATHS: NavigationPaths[] = [
  {
    path: PATHS.ELEVOVERSIGT,
    text: 'Elevoversigt',
  },
]
