import { Elev } from "@/model/elev";
import BaseDialog from "./BaseDialog";
import { forwardRef, useContext, useState } from "react";
import { resetPasswords } from "@/services/resetPasswordService";
import { ResetPasswordResponse } from "@/types/resetPassword";
import { UserContext } from "@/contexts/userContext";
import { createToastResetPasswords, createToastResetPasswordsError } from "@/util/toast";
import useToastStore from "@/store/toastStore";
import useInstitutionId from "@/hooks/institution-id";

type ResetPasswordDialogType = {
  readonly elever: Elev[];
  readonly close: () => void;
  readonly onResetPasswords: (result: ResetPasswordResponse) => void;
};

const ResetPasswordDialog = forwardRef<HTMLDialogElement, ResetPasswordDialogType>(
  ({ elever, close, onResetPasswords }, ref) => {
    const { csrfToken } = useContext(UserContext);
    const eleverFirstNames = elever.map((elev) => elev.getFullName()).join(elever.length === 2 ? " og " : ", ");
    const plural = elever.length > 1;
    const [loading, setLoading] = useState(false);
    const { addToast } = useToastStore();
    const institutionId = useInstitutionId();

    const reset = async () => {
      setLoading(true);
      const aktoerIds = elever.map((elev) => elev.getUuid());
      const result = await resetPasswords(aktoerIds, institutionId, csrfToken);
      if (result.statusCode.code === 200) {
        addToast(createToastResetPasswords(elever));
      } else if (result.statusCode.code)  {
        addToast(createToastResetPasswordsError(elever, result.statusCode));
      }
      onResetPasswords(result);
      setLoading(false);
    };

    return (
      <BaseDialog
        ref={ref}
        header="Nulstil adgangskode"
        primaryButtonText="Giv ny adgangskode"
        secondaryButtonText="Annuller"
        submitCallback={reset}
        close={close}
        loading={loading}
      >
        <p>
          Du er i gang med at give {eleverFirstNames} {plural ? "nye" : "ny"} {plural ? "adgangskoder" : "adgangskode"}.
        </p>
        <p>
          {plural ? "Eleverne" : eleverFirstNames} skal på {plural ? "deres" : "sin"} egen computer eller lign. logge ind med
          brugernavn for at skifte adgangskoden.
        </p>
      </BaseDialog>
    );
  },
);

export default ResetPasswordDialog;
