import { UserContext } from "@/contexts/userContext";
import { activate } from "@/services/elevListeService";
import useToastStore from "@/store/toastStore";
import { Elev } from "@/model/elev";
import { createToastElevAktiveret, createToastElevIkkeAktiveret } from "@/util/toast";
import { useContext, useEffect, useState } from "react";

export type OnCheckedCallback = (value: boolean, elev: Elev) => void;

type ElevTabelRowType = {
  readonly elev: Elev;
  readonly onActivatedElev: () => void;
  readonly showSpaerElev: (elev: Elev) => void;
  readonly showPolicy: (elev: Elev) => void;
  readonly isChecked: boolean;
  readonly onChecked: OnCheckedCallback;
};

export const checkboxLabel = "Marker række";
export default function ElevTabelRow({
  elev,
  showSpaerElev,
  isChecked,
  onActivatedElev,
  onChecked,
  showPolicy,
}: ElevTabelRowType) {
  const { loginAsUserAdmin, csrfToken } = useContext(UserContext);
  // lyt på isChecked fra master checkbox
  const [checked, setChecked] = useState(isChecked);
  const { addToast } = useToastStore();

  const handleChecked = (value: boolean) => {
    setChecked(value);
    onChecked(value, elev);
  };

  const activateElev = async (elev: Elev) => {
    const [result] = await activate([elev], csrfToken);
    if (result.success) {
      addToast(createToastElevAktiveret(elev));
    } else {
      addToast(createToastElevIkkeAktiveret(elev, result.errorCode.code));
    }
    onActivatedElev();
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const checkboxId = "checkbox" + elev.getUuid();

  return (
    <tr>
      <td>
        <div className="form-group-checkbox">
          <input id={checkboxId} onChange={({ currentTarget }) => handleChecked(currentTarget.checked)}
                 checked={checked} aria-label={checkboxLabel} type="checkbox" className="form-checkbox"/>
          <label className="form-label" htmlFor={checkboxId}/>
        </div>
      </td>
      <td>{elev.getFullName()}</td>
      <td>{elev.getKeycloakUsername()}</td>
      <td>{elev.getInstitutionClass()}</td>
      <td>{elev.getElevStatusListElement()}</td>
      {loginAsUserAdmin && (
        <td className="td-nowrap">
        {elev.getElevCallToActionButtons({ loginAsUserAdmin, activateElev, showSpaerElev, showPolicy })}
      </td>)}
    </tr>
  );
}
