import { Elev } from "@/model/elev";
import { useEffect, useState } from "react";

type ElevSpaerringType = {
  readonly elev?: Elev;
  readonly onCheckbox: Function;
}

export default function ElevSpaerring({elev, onCheckbox}: ElevSpaerringType) {
  const [konsekvens, setKonsekvens] = useState<boolean>(false);

  useEffect(() => {
    onCheckbox(!konsekvens)
  }, [konsekvens]);

  useEffect(() => {
    setKonsekvens(false);
  }, [elev?.getUuid()])

  return (
      <form>
        <legend>
          {elev?.getEnabled() ? ("Ønsker du at spærre ") : ("Ønsker du at ophæve spærring af ")}
          {elev?.getFullName()}?
        </legend>

        <fieldset className="mb-5">
          <div className="form-group">
            <div className="form-group-checkbox">
              <input
                  type="checkbox"
                  id="spaer-konsekevens"
                  name="spaer-konsekevens"
                  className="form-checkbox"
                  checked={konsekvens}
                  onChange={({currentTarget}) => setKonsekvens(currentTarget.checked)}
              />
              <label className="form-label" htmlFor="spaer-konsekevens">
                Er du indforstået med disse konsekvenser? (
                <a href="https://viden.stil.dk/display/OFFSKOLELOGIN/Unilogin+-+Elevadgang">læs mere</a>)
              </label>
            </div>
          </div>
        </fieldset>
      </form>
  );
}
