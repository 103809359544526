import { Elev } from "@/model/elev";
import { forwardRef, useContext, useState } from "react";
import BaseDialog from "@/components/dialog/BaseDialog";
import { toggleSpaerringForElev } from "@/services/spaerElevService";
import { UserContext } from "@/contexts/userContext";
import ElevSpaerring from "@/components/elevspaerring/ElevSpaerring";
import { createToastSpaer } from "@/util/toast";
import useToastStore from "@/store/toastStore";
import useInstitutionId from "@/hooks/institution-id";

type SpaerElevDialogType = {
  elev?: Elev;
  close: Function;
  onToggleSpaerring: () => void;
};

const SpaerElevDialog = forwardRef<HTMLDialogElement, SpaerElevDialogType>(
  ({ elev, close, onToggleSpaerring }: SpaerElevDialogType, ref) => {
    const headerString = elev?.getEnabled() ? `Spær ${elev?.getFullName()}` : `Ophæv spærring for ${elev?.getFullName()}`;
    const primaryButtonText = elev?.getEnabled() ? "Spær bruger" : "Ophæv spærring";
    const { csrfToken } = useContext(UserContext);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToastStore();
    const institutionId = useInstitutionId();

    const onCheckbox = (disabled: boolean) => {
      setSubmitDisabled(disabled);
    };

    const toggleSpaerring = async () => {
      setLoading(true);
      if (elev) {
        const { action, statusCode } =
          await toggleSpaerringForElev(elev, {aktoerId: elev.getUuid(), institutionId}, csrfToken);
        const resultToast = createToastSpaer(elev, action, statusCode);
        if (resultToast) {
          addToast(resultToast);
        }
      }
      onToggleSpaerring();
      setLoading(false);
    };

    return (
      <BaseDialog
        ref={ref}
        header={headerString}
        submitCallback={() => toggleSpaerring()}
        primaryButtonText={primaryButtonText}
        secondaryButtonText="Annuller"
        close={() => close()}
        loading={loading}
        primaryButtonDisabled={submitDisabled}
      >
        <ElevSpaerring elev={elev} onCheckbox={onCheckbox}/>
      </BaseDialog>
    );
  },
);

export default SpaerElevDialog;
