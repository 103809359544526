import { Elev } from "@/model/elev";
import { ResetPasswordResponse } from "@/types/resetPassword";
import { useEffect, useMemo, useState } from "react";

export default function useElever(initElever: Elev[]) {
  const [checkedElever, setCheckedElever] = useState<Elev[]>([]);
  const [elever, setElever] = useState(initElever);

  const eleverToActivate = useMemo(
    () => checkedElever.filter((elev) => !elev.getKeycloakActivated()),
    [checkedElever, elever],
  );

  const eleverToGiveAccess = useMemo(
    () => checkedElever.filter((elev) => !elev.getVoksenVerificeretUntil() && elev.getKeycloakActivated()),
    [checkedElever, elever],
  );

  const eleverToFortrydGiveAccess = useMemo(
    () => checkedElever.filter((elev) => elev.getVoksenVerificeretUntil()),
    [checkedElever, elever],
  );

  const eleverToResetPassword = useMemo(
    () => checkedElever.filter((elev) => elev.canTriggerResetPassword() && elev.getKeycloakActivated()),
    [checkedElever, elever],
  );

  const eleverToRecallResetPassword = useMemo(
    () => checkedElever.filter((elev) => !elev.canTriggerResetPassword()),
    [checkedElever, elever],
  );
  const allChecked = useMemo(() => checkedElever.length === elever.length, [checkedElever]);

  useEffect(() => {
    const updatedCheckedElever = initElever.filter((elev) => checkedElever.find((e) => e.getUuid() === elev.getUuid()));
    setElever([...initElever]);
    setCheckedElever([...updatedCheckedElever]);
  }, [initElever]);

  const onChecked = (checked: boolean, elev: Elev) => {
    if (checked) {
      setCheckedElever([...checkedElever, elev]);
    } else {
      const indexToRemove = checkedElever.findIndex((e) => e.getUuid() === elev.getUuid());
      checkedElever.splice(indexToRemove, 1);
      setCheckedElever([...checkedElever]);
    }
  };

  const updateEleverResetUntilTime = ({ data }: ResetPasswordResponse) => {
    const flatAktoerIds = data.map((id) => id.aktoerId);
    const eleverToUpdate = elever.filter((elev) => flatAktoerIds.includes(elev.getUuid()));
    eleverToUpdate.forEach((elev) => {
      const newResetUntil = data.find((d) => d.aktoerId === elev.getUuid())?.resetUntilDateTime;
      if (newResetUntil !== undefined) {
        elev.setResetUntilDateTime(newResetUntil);
      }
    });
    setElever([...elever]);
  };

  return {
    eleverToActivate,
    eleverToGiveAccess,
    eleverToFortrydGiveAccess,
    eleverToResetPassword,
    eleverToRecallResetPassword,
    setCheckedElever,
    onChecked,
    elever,
    updateEleverResetUntilTime,
    allChecked,
  };
}
