import useToastStore from "@/store/toastStore";
import { httpResponseToToast } from "@/util/toast";

export type RequestInitType = { method: "POST" | "PUT" | "GET"; token: string; body?: BodyInit };

export default async function baseFetch<T>(path: string, emptyResponse: T, requestOptions?: RequestInitType, ): Promise<T> {
  let request = requestOptions ? buildRequestInit(requestOptions) : {};
  return fetch(path, request).then(
    async (response) => {
      if (response.ok) {
        return await response.json();
      }
      handleErrorToast(response);
      return emptyResponse;
    },
    async (error) => {
      throw new Error("Der skete en fejl " + error);
    },
  );
}

export const buildRequestInit = (requestInit: RequestInitType): RequestInit => ({
  method: requestInit.method,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": requestInit.token,
  },
  body: requestInit.body,
});

const handleErrorToast = (response: Response) => {
  // Håndter toast her
  const toast = httpResponseToToast(response);
  if (toast) {
    const { addToast } = useToastStore.getState();
    addToast(toast);
  }
};
