import { PATHS } from "@/constants/paths";
import { UserInfo } from "@/types/userinfo";

export const getRequestedDeepUrl = (pathname: string, userInfo: UserInfo) => {
  if (!userInfo.isAuthenticated) {
    return PATHS.INDEX;
  }

  if (userInfo.statusCode?.code === 4000) {
    return PATHS.MISSING_PRIVILEGES;
  }

  if (userInfo.statusCode?.code === 4002) {
    return PATHS.WRONG_AKTOER_TYPER;
  }

  if (userInfo.statusCode?.code === 4004) {
    return PATHS.PRIVATE_IDENTITY_ERROR;
  }

  if (userInfo.statusCode?.code === 4042) {
    return PATHS.SECURITY_LEVEL_LOW;
  }

  if (!userInfo.institutions?.length) {
    return PATHS.NO_INSTITUTIONS;
  }

  if (userInfo.statusCode?.code >= 400) {
    return PATHS.SERVER_ERROR;
  }

  if (["/", PATHS.INDEX].includes(pathname)) {
    return PATHS.ELEVOVERSIGT;
  }

  return pathname;
};
