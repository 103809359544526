import { PATHS } from "@/constants/paths";
import { FormEventHandler, useRef } from "react";
import { getTokenFromCookie } from "@/util/cookieUtil";

type LogoutButtonProps = {
  readonly csrfToken: string;
};
export default function LogoutButton({ csrfToken }: LogoutButtonProps) {
  const csrfField = useRef<HTMLInputElement>(null);
  const submit: FormEventHandler<HTMLFormElement> = (submit) => {
    // Get latest csrf token on submit
    const csrf = getTokenFromCookie("XSRF-TOKEN=");
    if (csrf && csrfField.current) {
      csrfField.current.value = csrf;
      csrfField.current.dispatchEvent(new Event("change"));
    }
  };
  return (
    <form method="post" action={PATHS.LOGOUT_PATH} onSubmit={submit}>
      <input ref={csrfField} name="_csrf" type="hidden" value={csrfToken} />
      <button type="submit" className="button button-secondary d-print-none">
        Log af
      </button>
    </form>
  );
}
