import { UserInfo } from "@/types/userinfo";
import SolutionInfo from "./SolutionInfo";
import PortalInfo from "./PortalInfo";
import PortalInfoMobile from "./mobile/PortalInfoMobile";
import SolutionInfoMobile from "./mobile/SolutionInfoMobile";

export default function Header({ userinfo }: { readonly userinfo: UserInfo }) {
  return (
    <header className="header">
      <a href="#main-content" className="skipnav">
        Gå til sidens indhold
      </a>
      <PortalInfo user={userinfo} />
      <SolutionInfo />

      <div className="overlay"></div>
      <div className="mobile-drawer">

        <SolutionInfoMobile />
        <PortalInfoMobile userinfo={userinfo} />
      </div>
    </header>
  );
}
