import "../../assets/scss/index.scss";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";

import { UserInfo } from "@/types/userinfo";
import React, { useEffect } from "react";
import { getRequestedDeepUrl } from "@/util/paths";
import Layout from "../layout/Layout";

export default function Main() {
  const userinfo = useLoaderData() as UserInfo;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const requestedDeepUrl = getRequestedDeepUrl(pathname, userinfo);

    if(pathname !== requestedDeepUrl){
      navigate(requestedDeepUrl);
    }
  }, [userinfo.isAuthenticated]);

  return <Layout userinfo={userinfo} />;
}
