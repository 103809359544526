import StatusBesked from "@/components/statusbesked/StatusBesked";
import SeMereLink from "@/components/error/SeMereLink";

export default function MissingSecurityLevel() {
  return (
    <StatusBesked type={"error"} header={"Du kan ikke logge ind"}>
      <p>
        Dit sikringsniveau er for lavt og du kan derfor ikke logge ind.
      </p>
      <p>
        Hvis du ønsker at hæve dit sikringsniveau, skal du anvende 2-faktor eller tilsvarende. Prøv at logge af og på igen
        med MitID.
      </p>
      <SeMereLink/>
    </StatusBesked>
  );
}
