import StatusBesked from "../statusbesked/StatusBesked";

export default function ServerError() {
  return (
    <StatusBesked type="error" header="Der er sket en uventet fejl">
      <p>
        <a href="/index">Gå til forsiden</a> og forsøg at logge ind igen.
      </p>
      <p>
        Fortsætter problemet, kan du prøve at logge ind via en anden browser eller en anden computer.
      </p>
    </StatusBesked>
  );
}
