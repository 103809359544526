import { Toast } from "@/store/toastStore";
import { Elev } from "@/model/elev";
import { PasswordPolicy } from "@/types/passwordPolicy";
import { StatusCode } from "@/types/statusCode";

export const getTypeFromStatus = (statusCode: StatusCode) => {
  if (statusCode.code < 300) {
    return "success";
  }

  if (statusCode.code < 500) {
    return "warning";
  }
  return "error";
};
const createToast = (toast: Pick<Toast, "body" | "header" | "timeout" | "type">): Toast => {
  return {
    ...toast,
    uuid: self.crypto.randomUUID(),
  };
};

export const createToastElevSpaer = (elev: Elev): Toast =>
  createToast({ header: "Elev spærret", body: `${elev.getFullName()} er blevet spærret.`, type: "success", timeout: 5 });

export const createToastElevOphaevSpaerring = (elev: Elev): Toast =>
  createToast({
    header: "Spærring fjernet",
    body: `Spærring fjernet fra ${elev.getFullName()}.`,
    type: "success",
    timeout: 5,
  });

export const createToastElevSpaerError = (elev: Elev, statusCode: StatusCode): Toast =>
  createToast({
    type: getTypeFromStatus(statusCode),
    header: `Fejl ved spaerring af ${elev.getFullName()}`,
    body: `${statusCode.code}: ${statusCode.message}`,
  });

export const createToastElevOphaevSpaerringError = (elev: Elev, statusCode: StatusCode): Toast =>
  createToast({
    type: getTypeFromStatus(statusCode),
    header: `Fejl ved ophævning af spærring af ${elev.getFullName()}`,
    body: `${statusCode.code}: ${statusCode.message}`,
  });

export const createToastElevAktiveret = (elev: Elev): Toast =>
  createToast({ header: "Elev aktiveret", body: `${elev.getFullName()} er blevet aktiveret.`, type: "success", timeout: 5 });

export const createToastEleverAktiveret = (uuids: string[], total: number): Toast =>
  createToast({
    type: "success",
    header: "Elever aktiveret",
    body: `Du har aktiveret ${uuids.length} af ${total} elever.`,
    timeout: 5,
  });

export const createToastElevIkkeAktiveret = (elev: Elev, errorCode: number): Toast =>
  createToast({
    header: `Fejl i aktivering (kode ${errorCode})`,
    body: `${elev.getFullName()} er ikke blevet aktiveret.`,
    type: "error",
  });

export const createToastEleverIkkeAktiveret = (elever: Elev[]): Toast =>
  createToast({
    type: "warning",
    header: `Elev${elever.length > 1 ? "er" : ""} ikke aktiveret`,
    body: `Vi kunne ikke aktivere ${elever.length} elev${elever.length > 1 ? "er" : ""}.`,
  });

export const createToastEleverVoksenverificeret = (elever: Elev[]): Toast =>
  createToast({
    type: "success",
    header: `Elev${elever.length > 1 ? "er" : ""} givet adgang`,
    body: `Du har givet ${elever.length} elev${elever.length > 1 ? "er" : ""} adgang i 120 minutter`,
    timeout: 5,
  });

export const createToastElevListFejl = (institutionId: string): Toast =>
  createToast({
    type: "error",
    header: `Elever kunne ikke hentes`,
    body: `Vi kunne ikke hente elever for institution: ${institutionId}. Prøv igen senere`,
  });


export const createToastEleverIkkeVoksenverificeret = (elever: Elev[], statusCode: StatusCode): Toast =>
  createToast({
    type: getTypeFromStatus(statusCode),
    header: `Elev${elever.length > 1 ? "er" : ""} er ikke givet adgang`,
    body: `${statusCode.code}: ${statusCode.message}`,
  });

export const createToastFortrydAccess = (elever: Elev[]): Toast =>
  createToast({
    type: "success",
    header: `Du har fortrudt adgang for ${elever.length} elev${elever.length > 1 ? "er" : ""}`,
    body: `${elever.length} elev${elever.length > 1 ? "er" : ""} har fået fortrudt deres adgang`,
    timeout: 5,
  });

export const createToastFortrydAccessError = (elever: Elev[], statusCode: StatusCode): Toast =>
  createToast({
    type: getTypeFromStatus(statusCode),
    header: `Elev${elever.length > 1 ? "er" : ""} har ikke fået deres adgang fortrudt`,
    body: `${statusCode.code}: ${statusCode.message}`,
  });

export const createToastPolicyUpdated = (elev: Elev, policy: PasswordPolicy): Toast =>
  createToast({
    type: "success",
    header: `${elev.getFullName()} har ny adgangspolitik`,
    body: `Adgangspolitikken er sat til ${policy}`,
    timeout: 5,
  });

export const createToastPolicyUpdatedError = (elev: Elev, statusCode: StatusCode): Toast =>
  createToast({
    type: getTypeFromStatus(statusCode),
    header: `${elev.getFullName()} har ikke ny adgangspolitik`,
    body: `${statusCode.code}: ${statusCode.message}`,
  });

export const createToastResetPasswords = (elever: Elev[]): Toast => {
  const body =
    elever.length === 1
      ? `${elever[0].getFullName()} har fået nulstillet sin adgangskode`
      : `${elever.length} elever har fået nulstillet deres adgangskode`;

  return createToast({ type: "success", header: "Adgangskode nulstillet", body, timeout: 5 });
};

export const createToastResetPasswordsError = (elever: Elev[], statusCode: StatusCode): Toast => {
  const body =
    elever.length === 1
      ? `${elever[0].getFullName()} har ikke fået nulstillet sin adgangskode`
      : `${elever.length} elever har ikke fået nulstillet deres adgangskode`;

  return createToast({ type: "error", header: "Adgangskode ikke nulstillet", body });
};

export const createToastRecallResetPasswords = (elever: Elev[]): Toast => {
  const body =
    elever.length === 1
      ? `${elever[0].getFullName()} har fået fortryd nulstilling af adgangskode`
      : `${elever.length} elever har fået fortryd nulstilling deres adgangskode`;

  return createToast({ type: "success", header: "Nulstilling af adgangskode fortrudt", body, timeout: 5 });
};

export const createToastRecallResetPasswordsError = (elever: Elev[], statusCode: StatusCode): Toast => {
  const body =
    elever.length === 1
      ? `${elever[0].getFullName()} har ikke fået fortrudt nulstilling af sin adgangskode`
      : `${elever.length} elever har ikke fået fortrud nulstilling deres adgangskode`;

  return createToast({ type: getTypeFromStatus(statusCode), header: "Nulstilling af adgangskode ikke fortrudt", body });
};

export const createToastSpaer = (elev: Elev, action: string, statusCode: StatusCode): Toast | undefined => {
  if (action === "disable" && statusCode.code === 200) {
    return createToastElevSpaer(elev);
  }
  if (action === "enable" && statusCode.code === 200) {
    return createToastElevOphaevSpaerring(elev);
  }

  if (action === "disable" && statusCode.code > 200) {
    return createToastElevSpaerError(elev, statusCode);
  }
  if (action === "enable" && statusCode.code > 200) {
    return createToastElevOphaevSpaerringError(elev, statusCode);
  }
};

export const createStatusCodeToastWarning = (message: string) => {
  return createToast({ type: "warning", header: "Der skete en fejl", body: message });
};

export const createStatusCodeToastError = (message: string) => {
  return createToast({ type: "error", header: "Der skete en alvorlig fejl", body: message });
};

export const httpResponseToToast = (response: Response): Toast | null => {
  if (response.status < 400) {
    return null;
  }

  if (response.status === 400) {
    return createStatusCodeToastWarning("Der skete en fejl med den ønskede handling. Prøv igen.");
  }

  if (response.status === 401) {
    return createStatusCodeToastWarning("Du skal være logget ind for at foretage handlingen. Log ind og prøv igen.");
  }

  if (response.status === 403) {
    return createStatusCodeToastWarning("Du har ikke adgang til at foretage den ønskede handling.");
  }

  if (response.status === 405) {
    return createStatusCodeToastWarning("Den ønskede handling kan ikke foretages. Prøv at logge af og på igen" +
      ", for derefter at forsøge igen.");
  }

  if (response.status < 600) {
    return createStatusCodeToastError("Der skete en fejl. Prøv eventuelt igen.");
  }
  return null;
};
