import { PATHS } from "@/constants/paths";
import { UserContext } from "@/contexts/userContext";
import { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";

type ProtectedRouteType = {
  children: ReactNode;
};

export default function ProtectedRoute({ children }: ProtectedRouteType) {
  const userinfo = useContext(UserContext);
  return userinfo.isAuthenticated ? <>{children}</> : <Navigate to={PATHS.INDEX} />;
}
