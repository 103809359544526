import { useMemo } from "react";

type ButtonCountBadgeType = {
  readonly count: number;
  readonly buttonText: string;
  onClick: () => void;
  title: string;
};

export default function ButtonCountBadge({ count, buttonText, onClick, title }: ButtonCountBadgeType) {
  const disabled = useMemo(() => {
    return count === 0;
  }, [count]);
  return (
    <button type="button" className="button button-tertiary" onClick={() => onClick()} disabled={disabled} title={title}>
      {buttonText}
    </button>
  );
}
