import { useContext, useEffect, useState } from "react";
import { UserContext } from "@/contexts/userContext";

type ElevTableHeaderType = {
  readonly onCheckAll: (checked: boolean) => void;
  readonly checked: boolean;
};

export default function ElevTabelHeader({ onCheckAll, checked }: ElevTableHeaderType) {
  const userinfo = useContext(UserContext);
  const [isChecked, setIsChecked] = useState(checked);
  const onChange = (newChecked: boolean) => {
    onCheckAll(newChecked);
    setIsChecked(newChecked);
  };
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <thead>
    <tr>
      <th scope="col">
        <div className="form-group-checkbox">
          <input
            onChange={({ currentTarget }) => onChange(currentTarget.checked)}
            type="checkbox"
            value="true"
            id="activateAll"
            checked={isChecked}
            className="form-checkbox"
            title={"Vælg alle elever"}
          />
          <label className="form-label" htmlFor="activateAll"/>
        </div>
      </th>
      <th scope="col">Navn</th>
      <th scope="col">Brugernavn</th>
      <th scope="col">Klasse</th>
      <th scope="col">Status</th>
      {userinfo.loginAsUserAdmin && (
        <th scope="col">Handling</th>
      )}
    </tr>
    </thead>
  );
}
