import { Elev } from "@/model/elev";
import { components } from "./schema";

export type ElevListResponse = {
  elever: Elev[];
} & components["schemas"]["StudentList"];

export function mapToElever(response: ElevListResponse) {
  return response ? response.students.map((elev) => new Elev(elev)) : [];
}
