import StatusBesked from "@/components/statusbesked/StatusBesked";

export default function WrongAktoer() {
  return (
    <StatusBesked type="error" header="Kan ikke logge ind">
      <p>
        Det er kun medarbejdere, der kan logge ind på Elevadgang. Hvis du er i tvivl om hvor du skal logge ind, kan du
        kontakte din institution.
      </p>
      <p>
        Som medarbejder kan du finde hjælp i vores vejledninger på <a
        href="https://viden.stil.dk/pages/viewpage.action?pageId=88178695" className="nav-link">viden.stil.dk</a>
      </p>
    </StatusBesked>
  );
}
