import { Outlet } from "react-router-dom";
import InstitutionsListe from "@/components/institutionsListe/InstitutionsListe";
import { UserContext } from "@/contexts/userContext";
import { useContext } from "react";
import { UserInfo } from "@/types/userinfo";

export default function ElevOversigt() {
  const userinfo = useContext(UserContext) as UserInfo;

  return (
      <>
        <h1>Elevoversigt</h1>
        <InstitutionsListe className="mb-5" institutions={userinfo.institutions}/>
        <Outlet/>
      </>
  );
}
