import StatusBesked from "@/components/statusbesked/StatusBesked";
import SeMereLink from "@/components/error/SeMereLink";

export default function PrivateIdentityError() {
  return (
    <StatusBesked type="error" header="Det er ikke muligt at logge på Elevadgang med privat MitID">
      <p>
        For at logge på Elevadgang skal du benytte MitID Erhverv.
      </p>
      <p>
        Kontakt din virksomheds MitID Erhverv rettighedsadministrator for at få rettigheden <i>"Elevadgang: Ret til at
        administrere elever"</i> tilknyttet din bruger, så du kan få adgang til Elevadgang.
      </p>
      <SeMereLink/>
    </StatusBesked>
  );
}
