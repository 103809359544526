import { UserInfo } from "@/types/userinfo";
import { PATHS } from "@/constants/paths";

type LoginAdminButtonType = {
  readonly userinfo: UserInfo;
};

export default function LoginAdminButton({ userinfo }: LoginAdminButtonType) {
  return (
    <form action={PATHS.LOGIN_ADMIN_PATH} className="ml-3">
      <input name="_csrf" type="hidden" value={userinfo?.csrfToken} />
      <button className={"button button-secondary button-row"}>Log på som brugeradministrator</button>
    </form>
  );
}
