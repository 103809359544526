export default function ShortUserInfo({ userinfo }) {
  return (
    <div className="user">
      <p className="username">{userinfo.fullName}</p>
      {userinfo.loggedOnAs &&
        <p className="loggedOnAs"> Logget på som: {userinfo.loggedOnAs}</p>
      }
    </div>
  );
}
