import { PATHS } from "@/constants/paths";
import ElevadgangLogo from "../logo/Elevadgang";
import { Link } from "react-router-dom";

export default function SolutionInfo() {
  return (
    <div className="solution-info">
      <div className="solution-info-inner container">
        <div className="solution-heading">
          <Link to={PATHS.ELEVOVERSIGT}>
            <ElevadgangLogo />
          </Link>
        </div>
        <div className="additional-info">
          <p>
            <strong className="authority-name">Styrelsen for IT og Læring</strong>
          </p>
          <p>
            Support: <a href="tel:+4535878550">35 87 85 50</a>
            <span className="d-block d-lg-inline-block d-print-none mt-2 mt-lg-0">
              <span className="d-none d-lg-inline-block mr-2">· </span>
              <a href={"https://www.stil.dk/support"} className="icon-link function-link">
                Kontakt
                <svg className="icon-svg" focusable="false" aria-hidden="true">
                  <use xlinkHref="#open-in-new"></use>
                </svg>
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
