import { components } from "@/types/schema";

export type Student = components["schemas"]["Student"];

export class Elev {
  constructor(private elev: Student) {}

  getFirstName() {
    return this.elev.firstName;
  }

  getLastName() {
    return this.elev.lastName;
  }

  canTriggerResetPassword() {
    return this.elev.resetUntil === null;
  }

  getEnabled() {
    return this.elev.enabled;
  }

  getInstitutionClass() {
    return this.elev.institutionClass;
  }

  getInstitutionId() {
    return this.elev.institutionId;
  }

  getKeycloakUsername() {
    return this.elev.keycloakUsername;
  }

  getPolicyOverride() {
    return this.elev.policyOverride;
  }

  getKeycloakActivated() {
    return this.elev.keycloakActivated;
  }

  getStudentNeedsActivation() {
    return !this.getKeycloakActivated();
  }

  getVoksenVerificeretUntil() {
    return this.elev.voksenVerificeretUntil;
  }

  getUuid() {
    return this.elev.uuid;
  }

  getUniId() {
    return this.elev.uniId;
  }

  getResetUntilStatus() {
    if (this.elev.resetUntil) {
      return `Skift adgangskode inden kl. ${Intl.DateTimeFormat("da", { hour: "numeric", minute: "numeric" }).format(new Date(this.elev.resetUntil))}`;
    }
    return "";
  }

  getVoksenVerificeretUntilStatus() {
    if (this.elev.voksenVerificeretUntil) {
      return `Adgang givet indtil kl. ${Intl.DateTimeFormat("da", { hour: "numeric", minute: "numeric" }).format(new Date(this.elev.voksenVerificeretUntil))}`;
    }
    return "";
  }

  getPolicyOverrideStatus() {
    if (this.elev.keycloakActivated && this.elev.policyOverride) {
      return `Adgangskodepolitik låst til ${this.elev.policyOverride}`;
    }
    return "";
  }

  getUserNotEnabledStatus() {
    if (this.elev.keycloakActivated && !this.elev.enabled) {
      return "Brugeren er spærret";
    }
  }

  getKeycloakActivatedStatus() {
    if (!this.elev.keycloakActivated) {
      return "Elev skal aktiveres hos Brugeradministrator";
    }
    return "";
  }

  getNoPasswordStatus() {
    if (this.elev.keycloakActivated && this.elev.noPassword) {
      return (<div>Elev er aktiveret på Unilogin, men har endnu <i>ikke</i> fået adgangskode</div>);
    }
    return "";
  }

  getElevStatusListElement() {
    return (
      <ul className="unstyled-list">
        {this.getResetUntilStatus() && <li>{this.getResetUntilStatus()}</li>}
        {this.getVoksenVerificeretUntilStatus() && <li>{this.getVoksenVerificeretUntilStatus()}</li>}
        {this.getPolicyOverrideStatus() && <li>{this.getPolicyOverrideStatus()}</li>}
        {this.getUserNotEnabledStatus() && <li>{this.getUserNotEnabledStatus()}</li>}
        {this.getKeycloakActivatedStatus() && <li>{this.getKeycloakActivatedStatus()}</li>}
        {this.getNoPasswordStatus() && <li>{this.getNoPasswordStatus()}</li>}
      </ul>
    );
  }

  getFullName() {
    return this.elev.firstName + " " + this.elev.lastName;
  }

  getElevCallToActionButtons(
      {
        loginAsUserAdmin,
        activateElev,
        showSpaerElev,
        showPolicy,
      }: {
        loginAsUserAdmin: boolean;
        activateElev: (elev: Elev) => void;
        showSpaerElev: (elev: Elev) => void;
        showPolicy: (elev: Elev) => void;
      }) {

    const activated = this.elev.keycloakActivated;
    const buttonPolicyOrActivate = activated ? this.buttonAdgang(showPolicy) : this.buttonActivate(activateElev);
    const buttonSpaerOrOphaev = this.elev.enabled ? this.buttonSpaer(showSpaerElev) : this.buttonOphaevSpaerring(showSpaerElev);

    if (loginAsUserAdmin) {
      return (
          <div>
            {buttonPolicyOrActivate}
            <br/>
            {activated && buttonSpaerOrOphaev}
          </div>
      );
    }
    return <></>;
  }

  setResetUntilDateTime(resetUntilDateTime: string) {
    this.elev.resetUntil = resetUntilDateTime;
  }

  private buttonOphaevSpaerring(showSpaerElev: (elev: Elev) => void) {
    return (
        <button title="Vis elev" className="function-link" type="button" onClick={() => showSpaerElev(this)}>
          <svg className="icon-svg" focusable="false" aria-hidden="true">
              <use xlinkHref="#lock-open"></use>
            </svg>
          Ophæv Spærring
        </button>
    );
  }

  private buttonSpaer(showSpaerElev: (elev: Elev) => void) {
    return (
        <button title="Vis elev" className="function-link" type="button" onClick={() => showSpaerElev(this)}>
          <svg className="icon-svg" focusable="false" aria-hidden="true">
              <use xlinkHref="#lock"></use>
            </svg>
          Spær
        </button>
    );
  }

  private buttonActivate(activateElev: (elev: Elev) => void) {
    return (
        <button title="Aktiver elev" className="function-link activate" type="button" onClick={() => activateElev(this)}>
          <svg className="icon-svg" focusable="false" aria-hidden="true">
          <svg className="icon-svg" focusable="false" aria-hidden="true">
              <use xlinkHref="#person-add-alt-1"></use>
            </svg>
          </svg>
          Aktiver
        </button>
    );
  }

  private buttonAdgang(showPolicy: (elev: Elev) => void) {
    return (
        <button title="Vis elev" className="function-link" type="button" onClick={() => showPolicy(this)}>
          <svg className="icon-svg" focusable="false" aria-hidden="true">
              <use xlinkHref="#mode"></use>
            </svg>
          Adgangskodepolitik
        </button>
    );
  }
}
