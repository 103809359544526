import eleverForsidebillede from "@/assets/elevadgang-forsidebillede.jpg";

export default function Login() {
  return (
    <div className="login-grid">
      <div className="grid-text">
        <h2>Unilogin-værktøj til pædagogisk personale</h2>
        <p>
          Som pædagogisk personale kan du nemt hjælpe elever med at få en ny adgangskode og håndtere krav om øget sikkerhed.
        </p>
      </div>
      <div className="grid-image">
        <img src={eleverForsidebillede} alt="Elever der koncentrerer sig"/>
      </div>
    </div>
  );
}
