import { Elev } from "@/model/elev";
import BaseDialog from "./BaseDialog";
import { forwardRef, useContext, useState } from "react";
import { recallResetPasswords } from "@/services/resetPasswordService";
import { ResetPasswordResponse } from "@/types/resetPassword";
import { UserContext } from "@/contexts/userContext";
import { createToastRecallResetPasswords, createToastRecallResetPasswordsError } from "@/util/toast";
import useToastStore from "@/store/toastStore";
import useInstitutionId from "@/hooks/institution-id";

type RecallResetPasswordDialogType = {
  readonly elever: Elev[];
  readonly closeModal: () => void;
  readonly onResetPasswords: (result: ResetPasswordResponse) => void;
};

const RecallResetPasswordDialog = forwardRef<HTMLDialogElement, RecallResetPasswordDialogType>(
  ({ elever, closeModal, onResetPasswords: onRecallResetPasswords }, ref) => {
    const { csrfToken } = useContext(UserContext);
    const eleverFirstNames = elever.map((elev) => elev.getFullName()).join(elever.length === 2 ? " og " : ", ");
    const plural = elever.length > 1;
    const [loading, setLoading] = useState(false);
    const { addToast } = useToastStore();
    const institutionId = useInstitutionId();

    const recallReset = async () => {
      setLoading(true);
      const aktoerIds = elever.map((elev) => elev.getUuid());
      const result = await recallResetPasswords(aktoerIds, institutionId, csrfToken);
      if (result.statusCode.code === 200) {
        addToast(createToastRecallResetPasswords(elever));
      } else if (result.statusCode.code) {
        addToast(createToastRecallResetPasswordsError(elever, result.statusCode));
      }
      onRecallResetPasswords(result);
      setLoading(false);
    };

    return (
      <BaseDialog
        ref={ref}
        header="Fortryd giv ny adgangskode"
        primaryButtonText="Fortryd giv ny adgangskode"
        secondaryButtonText="Annuller"
        submitCallback={recallReset}
        close={closeModal}
        loading={loading}
      >
        <p>
          Vil du fortryde at {eleverFirstNames} har fået {plural ? "nye" : "ny"} {plural ? "adgangskoder" : "adgangskode"}?
        </p>
        <p>
          {plural ? "Eleverne" : eleverFirstNames} beholder {plural ? "deres" : "sin"} nuværende adgangskode.
        </p>
      </BaseDialog>
    );
  },
);

export default RecallResetPasswordDialog;
